
import ClipboardJS from "clipboard";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import anime from "animejs/lib/anime.es.js";
import getPrice from "./getPrice";

const BN = Web3.utils.BN;
window.BN = BN;

const coins = require("./contracts/coins");
const lpConfig = coins.LP;
const stakingConfig = coins.staking;

import ErrorNotice from "./component/ErrorNotice";

const chainMap = {
  "0x1": "Ethereum Main Network (Mainnet)",
  "0x3": "Ropsten Test Network",
  "0x4": "Rinkeby Test Network",
  "0x5": "Goerli Test Network",
  "0x2a": "Kovan Test Network",
};

export default {
  components: {
    ErrorNotice,
  },
  data() {
    return {
      headerShow: false,
      contractShow: false,
      coins,
      lpCoin: null,
      stakingCoin: null,
      w3: null,
      account: "",
      chain: "",
      history: [],
      loading: {
        approve: false,
        stake: false,
        reward: false,
        withdraw: false,
        withdrawAll: false,
      },
      balance: {
        a: "",
        b: "",
        lp: "",
      },
      approveData: {
        toApproveCount: 6,
        approvedCount: "",
      },
      stakeCount: "",
      withdrawCount: "",
      stakingData: {
        earned: "",
        rewards: "",
        isUnlocked: true,
        balanceOf: "",
        withdrawTime: "",
      },
      stakingStaticData: {
        rewardRate: "",
        totalSupply: "",
        duration: "",
      },
      errorMsg: {
        approve: [],
        stake: [],
        reward: [],
        withdraw: [],
        withdrawAll: [],
      },
      lockedTime: "",
      earnedShow: 0,
      provider: null,
      lpData: {
        lpPrice: 0,
      },
      price: {
        AUSD: "",
        BUSD: "",
      },
    };
  },
  async mounted() {
    await this.initMetaMask();
    if (!this.w3) {
      return;
    }
    if (this.account) {
      setInterval(() => {
        this.getEarned();
      }, 3000);
    }
  },
  methods: {
    async getLPData() {
      let price = await getPrice(this.provider);
      this.price = price;
      let totalSupply = await this.lpCoin.methods.totalSupply().call();
      let token0Address = await this.lpCoin.methods.token0().call();
      let token1Address = await this.lpCoin.methods.token1().call();
      let data = await this.lpCoin.methods.getReserves().call();

      const AddressMap = {};
      AddressMap[token0Address] = data["0"];
      AddressMap[token1Address] = data["1"];

      let amountA = AddressMap[this.coins.A.address] / 1e18;
      let amountB = AddressMap[this.coins.B.address] / 1e6;

      this.lpData.lpPrice =
        (amountA * price.AUSD + amountB * price.BUSD) / (totalSupply / 1e18);

      console.log(this.lpData);
    },
    earnPerDay() {
      let { balanceOf } = this.stakingData;
      balanceOf = 0.01 * 1e18;
      let { totalSupply, rewardRate } = this.stakingStaticData;
      let aAmount =
        (((balanceOf / totalSupply) * rewardRate) / 1e18) * 3600 * 24;
      let usd = aAmount * this.price.AUSD;
      return {
        aAmount: aAmount.toFixed(2),
        usd: usd.toFixed(2),
        daily: (usd / ((balanceOf / 1e18) * this.lpData.lpPrice)) * 100,
        apr: (usd / ((balanceOf / 1e18) * this.lpData.lpPrice)) * 100 * 365,
        apy:
          Math.pow(1 + usd / ((balanceOf / 1e18) * this.lpData.lpPrice), 365) *
          100,
      };
    },
    logout() {
      window.localStorage.clear();
      window.location.reload();
    },
    async startApp() {
      this.initContracts();
      this.getBalance();
      this.checkApprove();
      this.getStakingData();
      this.initClipBtn();
      this.getLPData();
    },
    async initWalletConnect() {
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://mainnet.infura.io/v3/f61f6eae2ce545c2bd609b2121b1de72",
        },
      });
      this.provider = provider;
      await provider.enable();
      this.w3 = new Web3(provider);
      //  Get Accounts
      const accounts = await this.w3.eth.getAccounts();
      this.account = accounts[0];
      this.startApp();
      //  Get Chain Id
      // const chainId = await this.w3.eth.chainId();
      // this.chain = chainMap[chainId]
    },
    initClipBtn() {
      new ClipboardJS(".clipBtn");
    },
    showAccount(val) {
      return `${val.slice(0, 4)}...${val.slice(-4)}`;
    },
    getEarned() {
      this.stakingCoin.methods
        .earned(this.account)
        .call()
        .then((res) => {
          anime({
            targets: this,
            earnedShow: res,
            round: 1,
            easing: "linear",
            duration: 1000,
            // update: function() {
            //   logEl.innerHTML = JSON.stringify(battery);
            // }
          });
        });
    },
    getStakingStaticData() {
      this.stakingCoin.methods
        .rewardRate()
        .call()
        .then((res) => {
          this.stakingStaticData.rewardRate = res;
        });
      this.stakingCoin.methods
        .totalSupply()
        .call()
        .then((res) => {
          this.stakingStaticData.totalSupply = res;
        });
      this.stakingCoin.methods
        .DURATION()
        .call()
        .then((res) => {
          this.stakingStaticData.duration = res;
        });
    },
    getStakingData() {
      this.getStakingStaticData();
      Object.keys(this.stakingData).forEach((it) => {
        this.stakingCoin.methods[it](this.account)
          .call()
          .then((res) => {
            this.stakingData[it] = res;
            if (it === "withdrawTime" && Date.now() < res * 1000) {
              this.lockedTime = new Date(res * 1000) - Date.now();
            }
            if (it === "earned") {
              this.earnedShow = res;
            }
          });
      });
    },
    getNum(val) {
      if (val === "") {
        return 0;
      }
      if (typeof val === "boolean") {
        return val.toString();
      }
      let finalVal = val / Math.pow(10, 18);
      return finalVal < 1 / 1e8 ? 0 : finalVal;
    },
    getBalance() {
      this.lpCoin.methods
        .balanceOf(this.account)
        .call()
        .then((res) => {
          this.balance.lp = res;
        });
      const coinA = new this.w3.eth.Contract(coins.A.abi, coins.A.address);
      coinA.methods
        .balanceOf(this.account)
        .call()
        .then((res) => {
          this.balance.a = res;
        });
      // const coinB = new this.w3.eth.Contract(coins.B.abi, coins.B.address);
      // coinB.methods
      //   .balanceOf(this.account)
      //   .call()
      //   .then((res) => {
      //     this.balance.b = res;
      //   });
    },
    initContracts() {
      this.lpCoin = new this.w3.eth.Contract(lpConfig.abi, lpConfig.address);
      this.stakingCoin = new this.w3.eth.Contract(
        stakingConfig.abi,
        stakingConfig.address
      );
    },
    checkApprove() {
      this.lpCoin.methods
        .allowance(this.account, stakingConfig.address)
        .call()
        .then((res) => {
          this.approveData.approvedCount = res;
        });
    },
    async getAccounts() {
      const accounts = await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          return accounts;
        })
        .catch((error) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log("Please connect to MetaMask.");
          } else {
            console.error(error);
          }
        });
      if (!accounts) {
        return;
      }
      this.account = accounts[0];
      window.ethereum.on("accountsChanged", (accounts) => {
        window.location.reload();
      });
      return accounts;
    },
    async initMetaMask() {
      const provider = await detectEthereumProvider();
      this.provider = provider;
      if (provider) {
        console.log("Ethereum successfully detected!");
        this.w3 = new Web3(window.ethereum);
        // From now on, this should always be true:
        // provider === window.ethereum
        // Access the decentralized web!
        // Legacy providers may only have ethereum.sendAsync
        const chainId = await provider.request({
          method: "eth_chainId",
        });

        this.chain = chainMap[chainId];
        ethereum.on("chainChanged", (chainId) => {
          window.location.reload();
        });
        let accounts = await this.getAccounts();
        if (accounts) {
          this.startApp();
        }
      } else {
        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error("Please install MetaMask!", error);
      }
    },
    approve() {
      const { lpCoin } = this;
      this.loading.approve = true;
      lpCoin.methods
        .approve(
          stakingConfig.address,
          new BN(this.approveData.toApproveCount).mul(
            new BN(10).pow(new BN(30))
          )
        )
        .send({ from: this.account })
        .then((res) => {
          this.checkApprove();
          Toast.success("Approve Success!");
        })
        .catch((e) => {
          this.errorMsg.approve.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
        })
        .finally(() => {
          this.loading.approve = false;
        });
    },
    stake() {
      const { stakingCoin } = this;
      const count = new BN(parseInt(this.stakeCount * 100000000)).mul(
        new BN(10).pow(new BN(10))
      );
      this.loading.stake = true;
      console.log(count);
      stakingCoin.methods
        .stake(count)
        .send({
          from: this.account,
        })
        .then((res) => {
          if (res.blockHash) {
            this.getStakingData();
            this.getBalance();
            this.stakeCount = "";
            Toast.success("Stake Success!");
          }
        })
        .catch((e) => {
          this.errorMsg.stake.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
        })
        .finally(() => {
          this.loading.stake = false;
        });
    },
    getReward() {
      const { stakingCoin } = this;
      this.loading.reward = true;
      stakingCoin.methods
        .getReward()
        .send({
          from: this.account,
        })
        .then((res) => {
          this.getStakingData();
          this.getBalance();
          Toast.success("Reward Success!");
        })
        .catch((e) => {
          this.errorMsg.reward.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
        })
        .finally(() => {
          this.loading.reward = false;
        });
    },
    withdraw() {
      const { stakingCoin } = this;
      const count = new BN(parseInt(this.withdrawCount * 100000000)).mul(
        new BN(10).pow(new BN(10))
      );
      this.loading.withdraw = true;
      stakingCoin.methods
        .withdraw(count)
        .send({
          from: this.account,
        })
        .then((res) => {
          this.getStakingData();
          this.getBalance();
          this.withdrawCount = "";
          Toast.success("Withdraw Success!");
        })
        .catch((e) => {
          this.errorMsg.withdraw.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
        })
        .finally(() => {
          this.loading.withdraw = false;
        });
    },
    withdrawAll() {
      const { stakingCoin } = this;

      this.loading.withdrawAll = true;
      stakingCoin.methods
        .exit()
        .send({
          from: this.account,
        })
        .then((res) => {
          this.getStakingData();
          this.getBalance();
          this.withdrawCount = "";
          Toast.success("Withdraw All & Reward Success!");
        })
        .catch((e) => {
          this.errorMsg.withdrawAll.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
        })
        .finally(() => {
          this.loading.withdrawAll = false;
        });
    },
  },
};
